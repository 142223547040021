/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import {PostObject} from './PostObject';

export class DailyReport extends PostObject {
    saledate: any;
    companyname: any;
    sale: any;
    tax: any;
    cost: any;
    profit: any;
    billcount: any;
}


export interface NFPostObject extends PostObject {
    id: number;
    method: string;
    params: any[];
}


export interface Error {
    message: string;
}

export interface NFResultObject {
    id: number;
    result?: any;
    error: Error;
}


export interface Data {
    company_logo?: any;
    db_name: string;
    show_dbs: boolean;
    login: string;
    password: string;
}

export interface Context {
    data: Data;
}

export interface ContextObject {
    context: Context;
}

export class JsonRPCRequest {
    jsonrpc: string;
    method: string;
    params: any;
    id: number;
    constructor() {
        this.jsonrpc = "2.0";
        this.method = "call";
        this.params = new JsonRPCParam();
    }
}

export class JsonRPCParam {
    args: any[];
    model: string;
    method: string;
    kwargs: KwargsObject;
    constructor() {

        this.kwargs = new KwargsObject();
        this.args = [];
    }
}

export class KwargsObject {
    context: ContextObject;
    constructor() {
        this.context = new ContextObject();
    }
}

export class ContextObject {
    lang: string;
    tz: string;
    uid: number;
    active_id: number;
    active_model: string;
    active_domain: any;
    search_disable_custom_filters: boolean;
    params: any;
    active_ids: any;

    constructor() {
        this.lang = "th_TH";
        this.tz = "Asia/Bangkok";
        this.uid = 1;
        this.search_disable_custom_filters = false;
    }
}
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import {Injectable, EventEmitter, Output} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {License, Amphures, Districts, Provinces, BaseUser, Company} from './services/BaseObject';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Inject, PLATFORM_ID, APP_ID} from '@angular/core';
import * as _ from 'underscore';
interface ShareObj {
    [id: string]: any;
}

@Injectable()
export class GlobalDataService {
    shareObj: ShareObj = {};
    @Output() userChangeEvent: EventEmitter<string> = new EventEmitter(true);
    @Output() selectTemplateChangeEvent: EventEmitter<any> = new EventEmitter(true);
    @Output() saveTemplateChangeEvent: EventEmitter<any> = new EventEmitter(true);

    @Output() offlineProductChangeEvent: EventEmitter<any> = new EventEmitter(true);

    dbName = "master_pico";
    probizURL = "http://183.88.242.189:9016";

    //dbName = "pwc";
    //probizURL = "http://192.168.3.27:9999";
    //probizURL = "http://192.168.3.4:9999";
    //probizURL = "http://183.88.242.189:9012";

    probizFileURL = "/static/db/trt_c/files/"

    defaultOffset = 0;
    defaultRow = 15;
    defaultField = "id";
    smart_pawn_role = 0;
    loading: boolean = false;
    file_source = "";
    no_image = "";
    logo_image = "";
    locations: any;
    headLimit = 10;
    lineLimit = 10000;

    companies: Company[];
    listProduct: any[] = [];
    userPermission: any[];
    userPermissionMain: any[];
    selecteds: any[];
    users: BaseUser[];
    provinces: Provinces[];
    districts: Districts[];
    amphures: Amphures[];
    license: License[];
    img: any = "http://183.88.242.189:2280/img_pico/";

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private title: Title, private router: Router) {
    //    this.shareObj['probizAPI'] = "http://183.88.242.189:9003/TrtcAPI/index.php/";
    this.shareObj['PicoAPI'] = "https://pico-api.pwcmall.in.th/index.php/";
    //this.shareObj['PicoAPI'] = "http://127.0.0.1/PicoAPI/index.php/";

    this.companies = [];
    this.no_image = this.shareObj['apiRoot'] + "/smart_veterinay/static/img/placeholder.png";
    this.logo_image = this.shareObj['apiRoot'] + "/web/binary/company_logo";
    this.probizFileURL = this.probizURL + "/static/db/" + this.dbName + "/files/";
    this.shareObj['BaseApi'] = this.shareObj['PicoAPI'] + "Main/";
    //add new
    this.shareObj['LoginApi'] = this.shareObj['PicoAPI'] + "Login/";
    this.shareObj['ContactApi'] = this.shareObj['PicoAPI'] + "Contact/";
    this.shareObj['ContractApi'] = this.shareObj['PicoAPI'] + "Contract/";
    this.shareObj['GuaranteeApi'] = this.shareObj['PicoAPI'] + "Guarantee/";
    this.shareObj['BaseuserApi'] = this.shareObj['PicoAPI'] + "BaseUser/";
    this.shareObj['PaymentApi'] = this.shareObj['PicoAPI'] + "Payment/";
    this.shareObj['LetterApi'] = this.shareObj['PicoAPI'] + "Letter/";
    this.shareObj['BlacklistApi'] = this.shareObj['PicoAPI'] + "Blacklist/";

    let jsonData: any = (function () {
      var result;
      $.ajax({
        type: 'GET',
        url: 'https://pwcapi.pwcmall.in.th/index.php/Main/getIP',
        dataType: 'json',
        async: false,
        success: function (data: any) {
          result = data;
        }
      });
      return result;
    })();
    try {
      if (jsonData.ip == "183.88.242.189") {
        console.log(jsonData.ip);
        this.checkIP();
      }
    } catch (e) {
      this.checkIP();
    }
  }
  checkIP() {
    this.probizURL = "http://192.168.20.219:80";
    this.img = "http://192.168.20.219:80/img_pico/";
    this.shareObj['PicoAPI'] = "https://pico-api.pwcmall.in.th/index.php/";
    this.shareObj['BaseApi'] = this.shareObj['PicoAPI'] + "Main/";
    //add new
    this.shareObj['LoginApi'] = this.shareObj['PicoAPI'] + "Login/";
    this.shareObj['ContactApi'] = this.shareObj['PicoAPI'] + "Contact/";
    this.shareObj['ContractApi'] = this.shareObj['PicoAPI'] + "Contract/";
    this.shareObj['GuaranteeApi'] = this.shareObj['PicoAPI'] + "Guarantee/";
    this.shareObj['BaseuserApi'] = this.shareObj['PicoAPI'] + "BaseUser/";
    this.shareObj['PaymentApi'] = this.shareObj['PicoAPI'] + "Payment/";
    this.shareObj['LetterApi'] = this.shareObj['PicoAPI'] + "Letter/";
    this.shareObj['BlacklistApi'] = this.shareObj['PicoAPI'] + "Blacklist/";
  }
    getIsPlatformBrowser() {
        return isPlatformBrowser(this.platformId);
    }
    setFileSource(fileSource: string) {
        this.file_source = this.shareObj['apiRoot'] + fileSource;
    }

    setTitle(newTitle: string) {
        this.title.setTitle(newTitle);
    }


    setDefaultMultipleSelect(companies: any[]) {
        if (this.shareObj['selectedCompany'] != null && companies.indexOf(this.shareObj['selectedCompany']) < 0) {
            companies.push(this.shareObj['selectedCompany']);
        }
    }

    setCompany(company_id: number) {
        try {
            this.shareObj['main'].setCompany(company_id);
        } catch (e) {

        }
    }

    getSelectedCompany() {
        return this.shareObj['main'].getSelectedCompany();
    }


    showMSG(severity: string, summary: string, msg: string) {
        let that = this;
        this.shareObj['main'].addSingle(severity, summary, msg);
    }

    checkPermissionByUrl(url: string): boolean {
        let bReturn = true;
        if (this.shareObj['main'].reports == null) {
            return bReturn;
        }
        for (let r of this.shareObj['main'].reports) {
            if (r.url === url) {
                bReturn = true;
                break;
            }
        }
        return bReturn;
    }

    redirect(url: string) {
        this.router.navigateByUrl(url);
    }

    checkPermission(permissions: string[]): boolean {
        let perm = false;

        let userPermission = [];

        //if null then anyone can access
        if (permissions == null) {
            return true;
        }

        for (let up of userPermission) {
            for (let p of permissions) {
                if (up === p) {
                    perm = true;
                    return perm;
                }
            }
        }

        return perm;
    }

    getSaveDateFormatWithTZ(d: Date): string {
        return this.getSaveDateTimeFormatWithTZ(d);
    }

    getNextDateFormatWithTZ(d: Date): string {
        let n = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        n.setDate(n.getDate() + 1);
        return this.getSaveDateTimeFormatWithTZ(n);
    }

    getSaveDateTimeFormatWithTZ(d: Date): string {
        let sReturn = "";
        let n = new Date(d.getTime());
        n.setHours(n.getHours() - 7);
        sReturn = n.getFullYear() + "-" + this.get2DigitZeroPad((n.getMonth() + 1)) + "-" + this.get2DigitZeroPad((n.getDate())) + " " + n.getHours() + ":" + n.getMinutes() + ":" + n.getSeconds();
        return sReturn;
    }

    getDateWithTZ(d: string): Date {
        let n = new Date(d);
        n.setHours(n.getHours() + 7);
        return n;
    }

    get2DigitZeroPad(data: any): string {
        let str = "" + data;
        let pad = "00";
        let ans = pad.substring(0, pad.length - str.length) + str;
        return ans;
    }

    getInitMany2One(value: any, field_id: string, field_name) {
        try {
            value[field_name] = value[field_id][1];
            value[field_id] = value[field_id][0];
        } catch (e) {

        }
    }


    getUserContext(variable: string) {
        let userContext = localStorage.getItem("user_context");
        if (!userContext) {
            if (variable == "tz") {
                return "Asia/Bangkok";
            }
            if (variable == "lang") {
                return "en_US";
            }
        }
        let obj = JSON.parse(userContext);

        if (!obj[variable]) {
            if (variable == "tz") {
                return "Asia/Bangkok";
            }
            if (variable == "lang") {
                return "en_US";
            }
        }
        return obj[variable];
    }

    getCookie(value: any) {
        return localStorage.getItem(value);
    }

    setCookie(value: any, data: any) {
        localStorage.setItem(value, data);
    }

    getNewRequest() {
        return new Object();
    }


    setStoreItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    getStoreItem(key: string) {
        return localStorage.getItem(key);
    }
    deleteStoreItem(key: string) {
        return localStorage.removeItem(key);
    }

    checkIsLogin() {

        if (localStorage.getItem("user") && localStorage.getItem("user") != null) {
            return true;
        }
        return false;
    }


    logout() {
        localStorage.clear();
        this.redirect("Probiz/login");
    }


    loadLogin() {
        if (window.location.href.indexOf("dashboard") < 0) {
            this.redirect("Probiz/dashboard");
        }

    }


    newDate(date: any) {
        if (!date) {
            return false;
        }
        let d: any = null;
        try {
            date = date.split(".")[0];
            d = new Date(date.replace(/-/g, '/'));
            //if date time
            //if (date.length > 8) {
            //    d.setHours(d.getHours() - (d.getTimezoneOffset() / 60));
            //}
        } catch (e) {
            try {
                d = new Date(date);
            } catch (e) {

            }
        }
        return d;
    }

    getSaveDateTimeFormat(d: Date): string {
        let sReturn = "";
        if (!d) {
            return sReturn;
        }
        let utc = new Date(d.getTime());
        utc.setHours(utc.getHours() + (utc.getTimezoneOffset() / 60));
        sReturn = utc.getFullYear() + "-" + this.get2DigitZeroPad((utc.getMonth() + 1)) + "-" + this.get2DigitZeroPad((utc.getDate())) + " " + this.get2DigitZeroPad((utc.getHours())) + ":" + this.get2DigitZeroPad((utc.getMinutes())) + ":" + this.get2DigitZeroPad((utc.getSeconds()));
        return sReturn;
    }


    getSaveDateFormat(d: Date): any {
        let sReturn = "";
        if (!d) {
            return false;
        }
        let utc = this.newDate(d);
        sReturn = utc.getFullYear() + "-" + this.get2DigitZeroPad((utc.getMonth() + 1)) + "-" + this.get2DigitZeroPad(utc.getDate());
        return sReturn;
    }

    getContext() {
        let o = JSON.parse(localStorage.getItem("context"));
        return o;
    }

    getLicensePage() {
        let o = localStorage.getItem("license_page").split(',');
        return o;
    }
    getLicenseBtn() {
        let o = localStorage.getItem("license_btn").split(',');
        return o;
    }
    checkPermissionBtn(url: string) {
      let getLicenseBtn  = this.getLicenseBtn();
        let permission = getLicenseBtn.filter((obj: any) => {return obj == url}).length > 0;
        return permission;
    }
    checkPermission2(model: string, action: string) {
        return true;
    }


    getCompanyId() {
        return Number(localStorage.getItem("company_id"));
    }


    isEmptyValue(value) {
        if (!value) {
            if (value == 0 || value == "0") {
                return false;
            }
            return true;
        }
        return false;
    }

    isEmptyValues(...values: any[]) {
        for (let v of values) {
            if (this.isEmptyValue(v)) {
                return true;
            }
        }
        return false;
    }


    getDefaultFormData() {
        let formData: FormData = new FormData();
        return formData;
    }
    checkPermission3(model: string, action: string): boolean {
        let perm = false;
        if (action == null) {
            return perm;
        }
        try {
            if (this.userPermission.length < 1) {
                return perm;
            }
        } catch (e) {
            return perm;
        }
        for (let up of this.userPermission) {
            if (up.code == action) {
                perm = true;
                return perm;
            }
        }
        return perm;
    }
    checkPermissionMain(model: string, action: string): boolean {
        let perm = false;
        if (action == null) {
            return perm;
        }
        try {
            if (this.userPermissionMain.length < 1) {
                return perm;
            }
        } catch (e) {
            return perm;
        }
        for (let up of this.userPermissionMain) {

            if (up.name == model) {
                if (action == "create") {
                    return up.create;
                }
                if (action == "delete") {
                    return up.delete;
                }
                if (action == "read") {
                    return up.read;
                }
                if (action == "write") {
                    return up.write;
                }
                if (action == "view_all") {
                    return up.view_all;
                }
                if (action == "modif_all") {
                    return up.modif_all;
                }
            }
        }
        return perm;
    }
    numberWithCommas0(x: number) {
        if (x == null) {
            return 0.000000;
        }

        let tmpNumber = Number(x).toFixed(6);
        let stmpNumber = tmpNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
        return stmpNumber.replace(".000000", ".000000");
    }
    numberWithCommas(x: number) {
        if (x == null) {
            return 0.00;
        }

        let tmpNumber = Number(x).toFixed(2);
        let stmpNumber = tmpNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return stmpNumber;
    }
    getNowDateFormat() {
        let date = new Date();
        let year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let hour = date.getHours().toString();
        hour = hour.length > 1 ? hour : '0' + hour;

        let min = date.getMinutes().toString();
        min = min.length > 1 ? min : '0' + min;

        let sec = date.getSeconds().toString();
        sec = sec.length > 1 ? sec : '0' + sec;

        return day + '/' + month + '/' + year + ' ' + hour + ':' + min + ':' + sec;
    }

    getNowDateFormat2() {
        let date = new Date();
        let year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let hour = date.getHours().toString();
        hour = hour.length > 1 ? hour : '0' + hour;

        let min = date.getMinutes().toString();
        min = min.length > 1 ? min : '0' + min;

        let sec = date.getSeconds().toString();
        sec = sec.length > 1 ? sec : '0' + sec;

        return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    }
    
}

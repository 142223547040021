/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PostObject } from './PostObject';
import { GlobalDataService } from '../globaldata.service';

@Injectable()
export class PostService {
    constructor(private http: HttpClient, private gd: GlobalDataService) {

    }

    post(url: string, req: any) {


        let headers = new HttpHeaders({
            "Content-Type": "application/json"
        });


        //NF Cookie
        //let cookie = JSON.parse(localStorage.getItem("context"));
        //if (cookie) {
        //    headers = new HttpHeaders({
        //        "Content-Type": "application/json",
        //        "Cookie": "token=" + cookie.token + "; user_id=" + cookie.user_id + "; company_name=" + encodeURI(cookie.company_name) + "; package_name=Enterprise; package=enterprise; user_name=" + cookie.user_name + "; dbname=main; company_id=" + cookie.company_id
        //    });
        //}

        let result = this.http.post(url, req, {
            headers: headers
            //      for keep session api
            //        ,withCredentials: true
        })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);

        //        result.then(resData => {
        //            let postObjext: PostObject = resData;
        //            if (postObjext.resultCode != null && postObjext.resultCode == "0001"){
        //                 this.gd.shareObj['main'].logined = false;
        //            }
        //        });

        return result;
    }

    get(url: string, req: any) {

        let headers = new HttpHeaders();
        headers.append('Content-Type',
            'application/x-www-form-urlencoded');
        let result = this.http.get(url, {
            headers: headers
            //      for keep session api
            //        ,withCredentials: true
        })
            .toPromise()
            .then(this.extractDataPDF)
            .catch(this.handleError);

        //        result.then(resData => {
        //            let postObjext: PostObject = resData;
        //            if (postObjext.resultCode != null && postObjext.resultCode == "0001"){
        //                 this.gd.shareObj['main'].logined = false;
        //            }
        //        });

        return result;
    }


    postForm(url: string, req: any) {
        let headers = new HttpHeaders();
        headers.append('Content-Type',
            'multipart/form-data');
        let result = this.http.post(url, req, { headers: headers, responseType: 'text' }).toPromise().then(this.extractData)
            .catch(this.handleError);;
        return result;
    }


    postSubSubscribe(url: string, req: any) {


        let headers = new HttpHeaders();
        headers.append('Content-Type',
            'application/x-www-form-urlencoded');
        let result = this.http.post(url, req, { headers: headers, withCredentials: true });
        //            .subscribe(response => {
        //                console.log(response);
        //                // if I am not mistaken "response.headers" should contain the "Set-Cookie" you are looking for 
        //            });



        return result;
    }

    private extractData(res: any) {
        //debugger;
        let body = res;
        return body || [];
    }

    private extractDataPDF(res: Response) {
        let body = res.blob();
        return body;
    }

    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        try {
            let errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            console.error(errMsg); // log to console instead
            //create error result object
            let result = new PostObject();
            result.msg = errMsg.status;
            result.resultCode = errMsg.status;
            result.detail = errMsg.statusText;
            return result;
        } catch (e) {
            console.log(error);
        }
        let result = new PostObject();
        result.msg = "Error"
        result.resultCode = "0010"
        result.detail = "No detail"
        return result;
    }
}


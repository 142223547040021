import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'Probiz/login',
    pathMatch: 'full',
  },
  {
    path: 'Probiz/login',
    loadChildren: () => import('./views/authen/authen.module').then(m => m.AuthenModule)
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'Probiz',
    component: DefaultLayoutComponent,
    data: {
      title: 'Probiz'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'contact',
          loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'contract',
          loadChildren: () => import('./views/contract/contract.module').then(m => m.ContractModule)
      },
      {
        path: 'guarantee',
          loadChildren: () => import('./views/guarantee/guarantee.module').then(m => m.GuaranteeModule)
      },
      {
        path: 'user',
          loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'payment',
          loadChildren: () => import('./views/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'letter',
          loadChildren: () => import('./views/letter/letter.module').then(m => m.LetterModule)
      },
      {
        path: 'blacklist',
          loadChildren: () => import('./views/blacklist/blacklist.module').then(m => m.BlacklistModule)
      },
      {
        path: 'receive',
          loadChildren: () => import('./views/receive/receive.module').then(m => m.ReceiveModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

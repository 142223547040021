/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

declare let x_tion: any;

import {PostObject} from './PostObject';



export class License extends PostObject {
    id: number;
    license_name: string;
    license_page: string;
    active: boolean;
}
export class BaseUser extends PostObject {
    id: number;
    profile_id: number;
    company_id: number;
    print_form: number;
    company_code: string;
    login: string;
    profile_name: string;
    name: string;
    user_id: number;
}
export class Provinces extends PostObject {
    id: number;
    code: string;
    name_th: string;
    name_en: string;
    geography_id: number;
}
export class Amphures extends PostObject {
    id: number;
    code: string;
    name_th: string;
    name_en: string;
    province_id: number;
}
export class Districts extends PostObject {
    id: number;
    zip_code: string;
    name_th: string;
    name_en: string;
    amphure_id: number;
}
export class Company extends PostObject {
    id: any;
    name: any;
    code: string;
    address: string;
    phone: string;
    fax: string;
    description: string;
}
import { Component, ViewChild, ContentChild,ElementRef } from '@angular/core';
import { navItems } from '../../_nav';
import { GlobalDataService } from '../../globaldata.service';
import { OdooRPCService } from '../../services/OdoorpcService';
import { OdooMasterService } from '../../services/NFMasterService';
import { Company } from '../../services/BaseObject';
import { NgZone } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { fromEvent, pipe, Observable } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router'
declare let cordova: any;
import * as _ from 'underscore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers: [OdooRPCService, OdooMasterService],
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;

  @ViewChild("qrCodeScannerModal") qrCodeScannerModal: any;
  @ViewChild("ProductModal") ProductModal: any;
  @ViewChild("selectCompanyModal") selectCompanyModal: any;
  @ViewChild('el', { static: false }) el: ElementRef;
  showQR: boolean;
  mouseDown$: Observable<any>;
  mouseUp$: Observable<any>;
  username: string;
  company_name: string;
  company: Company;
  companies: Company[] = [];
  company_code: string;

  companies2: Company[] = [];

  constructor(private gd: GlobalDataService, private odooMasterService: OdooMasterService, private odooRPC: OdooRPCService, private zone: NgZone, private router: Router) {
    this.username = localStorage.getItem("user");
    this.companies = [];
    let context = this.gd.getContext();
    this.company_name = context.company_name;
    this.company_code = context.company_code;

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.gd.logout();
  }

  changeCompany() {
    this.selectCompanyModal.show();
    if (this.companies.length == 0) {
      this.odooMasterService.getCompanyChangeDropdown().then(res => {
        for (let r of res) {
          this.companies.push(Object.assign(new Company(), { code: r[0], name: r[1] }));
        }
      });

      this.odooMasterService.getCompanies().then(res => {
        if (res) {
          this.companies2 = res;
        }
      });

    }
  }

  selectCompany() {

    let company = null;
    for (let c of this.companies2) {
      if (c.name == this.company_code) {
        company = c;
        break;
      }
    }

    if (!company) {
      return;
    }

    this.odooRPC.call("select.company", "create", [{ "company": this.company_code }], {}).then(res => {
      if (res) {
        let context = this.gd.getContext();
        context.company_id = company.id;
        let company_id = context.company_id;
        context.company_name = company.name;
        this.gd.setStoreItem("context", JSON.stringify(context));
        localStorage.setItem("company_id", company_id);
        this.gd.setCompany(company_id);
        window.location.reload();
      }
    });
  }
  //onClick() {
  //  this.mouseDown$.pipe(delay(1000), takeUntil(this.mouseUp$)).subscribe(res => {
  //    this.router.navigate(['/Probiz/list/main']);
  //  });
  //    this.getCamera();
  //}
  getCamera() {
    if (window.hasOwnProperty("cordova")) {
      let that = this;
      cordova.plugins.barcodeScanner.scan(

        function (result: any) {
          if (result.text == null || result.text == "") {

          } else {
            that.zone.run(() => {
              that.selection(result.text);
            });
          }

        },
        function (error: any) {
        },
        {
          preferFrontCamera: false, // iOS and Android
          showFlipCameraButton: true, // iOS and Android
          showTorchButton: true, // iOS and Android
          torchOn: false, // Android, launch with the torch switched on (if available)
          saveHistory: false, // Android, save scan history (default false)
          prompt: "กรุณาให้ตำแหน่งของ Barcode อยู่ภายในกรอบที่กำหนด", // Android
          resultDisplayDuration: 900, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
          //                formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
          orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
          disableAnimations: true, // iOS
          disableSuccessBeep: true // iOS and Android
        }
      );
    } else {
      this.scan();
    }
  }
  scan() {
    this.qrCodeScannerModal.show();
  }
  product: any = new Object();
  selection(text: any) {
//    if (text == null || text.length < 1) {
//      return
//    }
//    let that = this;
//    let product;
//    try {
//      product = that.gd.products.filter((obj: Product) => { return (obj.pcode.indexOf(text) == 0 || that.barcodeReturn(obj.barcode).indexOf(text) == 0) });
//      if (!product[0].pid) {
//        this.gd.showMSG("error", "Error", "ไม่พบสินค้าที่ท่านค้นหา");
//        return
//      }
//      try {
//        let listProduct;
//        this.gd.listProduct = JSON.parse(this.gd.getStoreItem("listProduct"));
//        listProduct = this.gd.listProduct.filter((obj: Product) => { return (obj.pcode.indexOf(text) == 0 || that.barcodeReturn(obj.barcode).indexOf(text) == 0) });
//        if (!listProduct[0].pid) {
//          this.product.qty = 1;
//        } else {
//          this.product.qty = listProduct[0].qty;
//        }
//      } catch (e) { }
//      this.product.product_id = product[0].pid;
//      this.product.product_code = product[0].pcode;
//      this.product.product_name = product[0].pname;
//      this.product.product_fullname = '['+product[0].pcode+'] '+product[0].pname;
//      
//      this.product.unit = product[0].unit_name;
//      this.product.uom_id = product[0].uom_id;
//      this.product.parent_id = product[0].parent_id;
//      this.product.tax_id = product[0].tax_id;
//      this.ProductModal.show();
//      //this.gd.listProduct = [];
//    } catch (e) { console.log(e)}

  }

  memory_list(product: any) {
      if(product.price <= 0){
          this.gd.showMSG("error", "ข้อมูลไม่ครบถ้วน", "กรุณาเลือกขนาดสินค้า");
            return;
      }
    let that = this;
    this.gd.listProduct = JSON.parse(this.gd.getStoreItem("listProduct"));
    that.zone.run(() => {

      if (!this.gd.listProduct) {
        this.gd.listProduct = [];
        this.gd.listProduct.push(product);
      } else {
        try {
          let listProduct;
          listProduct = this.gd.listProduct.filter((obj: any) => { return (obj.product_code.indexOf(product.product_code) == 0) });
//          if (listProduct[0].product_id) {
//            for (let x of this.gd.listProduct) {
//              if (x.product_code == listProduct[0].product_code) {
//                x.qty = product.qty;
//              }
//            }
//          } else {
            this.gd.listProduct = this.gd.listProduct.concat(product);

//          }
        } catch (e) {
          console.log(e);
          this.gd.listProduct = this.gd.listProduct.concat(product);
        }


      }
      this.gd.deleteStoreItem("listProduct");
      this.gd.setStoreItem("listProduct", JSON.stringify(this.gd.listProduct));
      try {
        this.ProductModal.hide();
      } catch (e) {
        console.log(e);
      }
    });
  }
  barcodeReturn(text: string) {
    if (text == "" || text == null) {
      return " ";
    } else {
      return text;
    }
  }
  ngAfterViewInit() {
    //this.mouseDown$ = fromEvent(this.el.nativeElement, 'mousedown').pipe(tap());
    //this.mouseUp$ = fromEvent(this.el.nativeElement, 'mouseup').pipe(tap());
  }
}

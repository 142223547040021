import { Injectable } from '@angular/core';
import { OdooRPCService } from './OdoorpcService';
import { PostService } from './PostService';
import { GlobalDataService } from '../globaldata.service';
import { JsonRPCRequest, ContextObject } from './NfObject';
import { BaseView } from '../../app/views/BaseView';

@Injectable()
export class OdooMasterService extends BaseView {

    loginUrl: string;
    apiUrl: string;
    apiRootUrl: string;


    constructor(private gd: GlobalDataService, private ps: PostService, private odooRPC: OdooRPCService) {
        super(gd);
        this.loginUrl = this.gd.shareObj['loginapi']
        this.apiUrl = this.gd.shareObj['api'];
        this.apiRootUrl = this.gd.shareObj['apiRoot'];
    }

    selectDatabase(selectDataBase: string) {

        return this.ps.post(this.apiRootUrl + '/web/database/selector?db=' + selectDataBase, {});
    }

    //search list
    getPQR() {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "purchase.request";
        req.params[1] = "search_read";

        let filter: any[] = [];
        filter.push(["number", "ilike", "PRG002004-0052"]);
        req.params[2] = [];
        req.params[2].push(filter);
        //option object
        let options: any = Object();
        options = { "field_names": ["number", "ref", "employee_id", "date", "date_required", "other_info", "state"], "limit": 200, "count": true };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getContactAutoComplete(filterString: string) {

        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "contact";
        req.params[1] = "search_read";

        let filter: any[] = [];

        filter.push("or");
        filter.push(["name", "ilike", filterString]);
        filter.push(["code", "ilike", filterString]);


        req.params[2] = [];
        req.params[2].push(filter);
        //option object
        let options: any = Object();
        options = { "field_names": ["code", "name"], "limit": 10 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            if (res) {
                let list = res;
                for (let l of list) {
                    l.name = "[" + l.code + "] " + l.name;
                }
                return list;
            } else {
                //Error
            }
            return res;
        });

    }

    getEmployeeAutoComplete(filterString: string) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "hr.employee";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push(filterString);

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": [], "limit": 10 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getProductAutoComplete(filterString: string) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "product";
        req.params[1] = "search_read";

        let filter: any[] = [];

        filter.push("or");
        filter.push(["name", "ilike", filterString]);
        filter.push(["code", "ilike", filterString]);

        /*
        let filter1: any[] = [];
        filter1.push("or");
        filter1.push(["code", "ilike", filterString]);
        filter1.push(["name", "ilike", filterString]);
        filter.push(filter1);
        */

        req.params[2] = [];
        req.params[2].push(filter);
        //option object
        let options: any = Object();
      options = { "field_names": ["id", "code", "name_report", "uom_id", "sale_price", "zone_name", "top_stock", "purchase_price","description"], "limit": 10 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            if (res) {
                let list = res;
                for (let l of list) {
                  this.getInitMany2One(l, "uom_id", "uom_name");
                  this.getInitMany2One(l, "zone_name", "zone_name2");
                  this.getInitMany2One(l, "top_stock", "top_stock2");
                    l.pname = "[" + l.code + "] " + l.name_report;

                }
                return list;
            } else {
                //Error
            }
            return res;
        });
    }


    getLocationAutoComplete(filterString: string) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "stock.location";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push(filterString);

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": [], "limit": 10 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


    getPurchaseAutoComplete(filterString: string) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "purchase.order";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push(filterString);

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": [], "limit": 10 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getAccountDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "account.account";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


    getSequenceDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "sequence";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 100 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getTaxRateDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "account.tax.rate";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


    getAccountTrackDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "account.track.categ";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 100 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getCurrencyDropdown() {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "currency";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": [], "limit": 100 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getBudgetDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "account.budget";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 100 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getPaymentTermDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "payment.terms";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 100 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getAddressDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "address";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 100 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


    getCompanyChangeDropdown() {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "select.company";
        req.params[1] = "get_companies";

        let filter: any[] = [];

        req.params[2] = filter;

        let options: any = Object();

        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getCompanies() {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "company";
        req.params[1] = "search_read";

        let filter: any[] = [];

        req.params[2] = [];
        req.params[2].push(filter);

        let options: any = Object();
        options = { "field_names": ["code", "name"], "limit": 20 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getUOMDropdown() {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "uom";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": [], "limit": 100 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getPriceListDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "price.list";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getPurchaseTypeDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "purchase.type";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


    getPurchaseRefDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "purchase.ref";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getShippingMethodDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "ship.method";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getDriverDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "pwc.driver";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


    getTruckDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "truck";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getReportTemplate(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "report.template";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


    getLookupInvoice(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "account.invoice";
        req.params[1] = "search_read";
        req.params[2] = conditions;
        let options: any = Object();
        options = { "field_names": ["number", "tax_no", "ref", "inv_type", "contact_id", "date", "due_date", "amount_paid", "amount_due", "currency_id", "memo", "state", "is_bill"], "limit": 200, "count": true };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }

    getBankDropdown(conditions: any) {
        let req = new JsonRPCRequest();
        req.params = [];
        req.params[0] = "bank";
        req.params[1] = "name_search";

        let filter: any[] = [];
        filter.push("");

        req.params[2] = filter;

        let options: any = Object();
        options = { "condition": conditions, "limit": 1000 };
        req.params[3] = options;
        return this.odooRPC.call(req.params[0], req.params[1], req.params[2], req.params[3]).then(res => {
            return res;
        });
    }


}

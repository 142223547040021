import { INavData } from '@coreui/angular';


export const navItems: INavData[] = [
  {
    name: 'Home',
    url: '/Probiz/saleorder',
    icon: 'icon-home'
  },
//  {
//    name: 'Purchase',
//    url: '/Probiz/purchase',
//    icon: 'icon-puzzle',
//    children: [
//      {
//        name: 'Dashboard',
//        url: '/Probiz/purchase/main',
//        icon: 'icon-puzzle'
//      },
//      {
//        name: 'ใบขอซื้อ (PRG)',
//        url: '/Probiz/purchase/prg',
//        icon: 'icon-puzzle'
//      },
//      {
//        name: 'ใบขอซื้อ PO',
//        url: '/Probiz/purchase/po',
//        icon: 'icon-puzzle'
//      }
//    ]
//  },
//  {
//    name: 'Account',
//    url: '/Probiz/account',
//    icon: 'icon-cursor',
//    children: [
//      {
//        name: 'Dashboard',
//        url: '/Probiz/account/main',
//        icon: 'icon-cursor'
//      },
//      {
//        name: 'RC',
//        url: '/Probiz/account/rc',
//        icon: 'icon-cursor'
//      },
//      {
//        name: 'CC',
//        url: '/Probiz/account/cc',
//        icon: 'icon-cursor'
//      },
//      {
//        name: 'CBI',
//        url: '/Probiz/account/cbi',
//        icon: 'icon-puzzle'
//      },
//      {
//        name: 'SQ',
//        url: '/Probiz/account/sq',
//        icon: 'icon-puzzle'
//      }
//    ]
//  },
//  {
//    name: 'Inventory',
//    url: '/Probiz/stock',
//    icon: 'icon-pie-chart',
//    children: [
//      {
//        name: 'Dashboard',
//        url: '/Probiz/stock/main',
//        icon: 'icon-cursor'
//      },
//
//      {
//        name: 'ใบร้องขอ PQA',
//        url: '/Probiz/stock/pqa',
//        icon: 'icon-cursor'
//      },
//      {
//        name: 'ใบรับเข้า GR',
//        url: '/Probiz/stock/gr',
//        icon: 'icon-cursor'
//      },
//      {
//        name: 'ใบร้องขอ GT',
//        url: '/Probiz/stock/gt',
//        icon: 'icon-cursor'
//      }
//    ]
//  },
  {
    title: true,
    name: 'รายการสินค้า'
  },
  {
    name: 'List',
    url: '/Probiz/list/main',
    icon: 'icon-basket'
  },
  {
    title: true,
    name: 'Product'
  },
//  {
//    name: 'ทุกรายการ',
//    url: '/Probiz/product/main',
//    icon: 'icon-list',
//    children: [
      {
        name: 'COFFEE',
        url: ['/Probiz/product/main', {categ:'COFFEE'}],
        icon: 'icon-cup',
        
      }, {
        name: 'NON-COFFEE',
        url: ['/Probiz/product/main', { categ: 'NON-COFFEE' }],
        icon: 'icon-cup',
      }
//    ]
//  }
];



/*
export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/Probiz/dashboard',
    icon: 'icon-speedometer'
  },
  {
    title: true,
    name: 'Theme'
  },
  {
    name: 'Master',
    url: '/Probiz/master',
    icon: 'icon-pie-chart',
    children: [
      {
        name: 'User',
        url: '/Probiz/master/user',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Purchase',
    url: '/Probiz/purchase',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Dashboard',
        url: '/Probiz/purchase/main',
        icon: 'icon-puzzle'
      },
      {
        name: 'ใบขอซื้อ (PQR)',
        url: '/Probiz/purchase/pqr',
        icon: 'icon-puzzle'
      },
      {
        name: 'สร้าง PO',
        url: '/Probiz/purchase/pqr',
        icon: 'icon-puzzle'
      },
      {
        name: 'สร้าง PQ',
        url: '/Probiz/purchase/pq',
        icon: 'icon-puzzle'
      },
      {
        name: 'Reports',
        url: '/Probiz/purchase/reports',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Account',
    url: '/Probiz/account',
    icon: 'icon-cursor',
    children: [
      {
        name: 'Dashboard',
        url: '/Probiz/account/main',
        icon: 'icon-cursor'
      },
      {
        name: 'สร้าง RC',
        url: '/Probiz/account/rc',
        icon: 'icon-cursor'
      },
      {
        name: 'สร้าง CC',
        url: '/Probiz/account/cc',
        icon: 'icon-cursor'
      },
      {
        name: 'สร้าง CBI',
        url: '/Probiz/account/cbi',
        icon: 'icon-cursor'
      },
      {
        name: 'สร้าง SQ',
        url: '/account/sq',
        icon: 'icon-cursor'
      },
      {
        name: 'Reports',
        url: '/Probiz/account/reports',
        icon: 'icon-cursor'
      },
      {
        name: 'Receipt Approval',
        url: '/Probiz/account/receipt-approval',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Inventory',
    url: '/Probiz/stock',
    icon: 'icon-pie-chart',
    children: [
      {
        name: 'สร้าง PQA',
        url: '/Probiz/stock/pqa',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Sale',
    url: '/Probiz/sales',
    icon: 'icon-star',
    children: [
      {
        name: 'Dashboard',
        url: '/Probiz/sales/coreui-icons',
        icon: 'icon-star'
      },
      {
        name: 'Billing Note',
        url: '/Probiz/sales/coreui-icons',
        icon: 'icon-star'
      },
      {
        name: 'Billing Note Customer',
        url: '/Probiz/sale/bn-customer',
        icon: 'icon-star'
      },
      {
        name: 'Full tax invoice',
        url: '/Probiz/sale/full-tax-invoice',
        icon: 'icon-star'
      }
    ]
  }
];

*/

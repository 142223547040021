import { GlobalDataService } from '../globaldata.service';
import { UnderscoreStatic } from 'underscore';
import { jquery } from 'jquery';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { SelectItem } from 'primeng/api';

declare var jsPDF: any;

export class BaseView {

    public _: UnderscoreStatic;
    public $: jquery;
    public offsetDataSize = 200;
    public image_extexsions: string[] = [];
    monthNamesThai: any[] = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
        "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤษจิกายน", "ธันวาคม"];
    dayNames: any[] = ["วันอาทิตย์ที่", "วันจันทร์ที่", "วันอังคารที่", "วันพุทธที่", "วันพฤหัสบดีที่", "วันศุกร์ที่", "วันเสาร์ที่"];
    monthNamesEng: any[] = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    dayNamesEng: any[] = ['Sunday', 'Monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    company_id: number;

    constructor(private bgd: GlobalDataService) {
        this._ = _;
        this.$ = $;
        this.image_extexsions = ['jpg', 'png', 'gif', 'bmp', 'jpeg'];


        this.company_id = Number(this.bgd.getStoreItem("company_id"));
    }

    getBooleanFromString(value: any): boolean {
        let bReturn = false;

        if (value != null) {
            if (value === "t" || value == true || value === "True" || value === "true") {
                bReturn = true;
            }
        }

        return bReturn;
    }

    getSaveDateFormat(d: Date): any {
        let sReturn = "";
        if (!d) {
            return false;
        }
        let utc = this.newDate(d);
        sReturn = utc.getFullYear() + "-" + this.get2DigitZeroPad((utc.getMonth() + 1)) + "-" + this.get2DigitZeroPad(utc.getDate());
        return sReturn;
    }

    getNextDateFormat(d: Date): any {
        let n = new Date(d.getTime());
        if (!d) {
            return false;
        }
        n.setDate(n.getDate() + 1);
        let sReturn = "";
        sReturn = n.getFullYear() + "-" + this.get2DigitZeroPad((n.getMonth() + 1)) + "-" + this.get2DigitZeroPad(n.getDate());
        return sReturn;
    }

    getSaveNextDateFormat(d: Date): string {
        let n = new Date(d.getTime());
        n.setDate(n.getDate() + 1);
        let sReturn = "";
        sReturn = n.getFullYear() + "-" + this.get2DigitZeroPad((n.getMonth() + 1)) + "-" + this.get2DigitZeroPad(n.getDate());
        return sReturn;
    }

    getSaveDateTimeFormat(d: Date): string {
        let sReturn = "";
        let utc = new Date(d.getTime());
        utc.setHours(utc.getHours() + (utc.getTimezoneOffset() / 60));
        sReturn = utc.getFullYear() + "-" + this.get2DigitZeroPad((utc.getMonth() + 1)) + "-" + this.get2DigitZeroPad((utc.getDate())) + " " + this.get2DigitZeroPad((utc.getHours())) + ":" + this.get2DigitZeroPad((utc.getMinutes())) + ":" + this.get2DigitZeroPad((utc.getSeconds()));
        return sReturn;
    }



    getSaveTimeFormat(d: Date): string {
        let sReturn = "";
        let utc = new Date(d.getTime());
        utc.setHours(utc.getHours() + (utc.getTimezoneOffset() / 60));
        sReturn = this.get2DigitZeroPad((utc.getHours())) + ":" + this.get2DigitZeroPad((utc.getMinutes())) + ":" + this.get2DigitZeroPad((utc.getSeconds()));
        return sReturn;
    }


    getSaveNextDateTimeFormat(d: Date): string {
        let sReturn = "";
        let utc = new Date(d.getTime());
        utc.setHours(utc.getHours() + (utc.getTimezoneOffset() / 60));
        utc.setDate(utc.getDate() + 1);
        sReturn = utc.getFullYear() + "-" + this.get2DigitZeroPad((utc.getMonth() + 1)) + "-" + this.get2DigitZeroPad((utc.getDate())) + " " + this.get2DigitZeroPad((utc.getHours())) + ":" + this.get2DigitZeroPad((utc.getMinutes())) + ":" + this.get2DigitZeroPad((utc.getSeconds()));
        return sReturn;
    }


    get2DigitZeroPad(data: any): string {
        let str = "" + data;
        let pad = "00";
        let ans = pad.substring(0, pad.length - str.length) + str;
        return ans;
    }

    numberWithCommas(x: number): string {
        if (x == null) {
            return "0.00";
        }

        let tmpNumber = Number(x).toFixed(2);
        let stmpNumber = tmpNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return stmpNumber.replace(".00", "");
    }


    getOdooString(o: any) {
        if (o) {
            return o;
        }
        return "";
    }

    getInitMany2One(value: any, field_id: string, field_name) {
        try {
            value[field_name] = value[field_id][1];
            value[field_id] = value[field_id][0] || false;
        } catch (e) {
            value[field_id] = false;
        }
    }


    getSaveDateFormatWithOutTZ(d: Date): string {
        return this.getSaveDateTimeFormatWithOutTZ(d);
    }

    getNextDateFormatWithTZ(d: Date): string {
        let n = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        n.setDate(n.getDate() + 1);
        return this.getSaveDateTimeFormatWithOutTZ(n);
    }

    getSaveDateTimeFormatWithOutTZ(d: Date): string {
        let sReturn = "";
        let n = new Date(d.getTime());
        sReturn = n.getFullYear() + "-" + this.get2DigitZeroPad((n.getMonth() + 1)) + "-" + this.get2DigitZeroPad((n.getDate())) + " " + n.getHours() + ":" + n.getMinutes() + ":" + n.getSeconds();
        return sReturn;
    }

    newDate(date: any) {
        if (!date) {
            return date;
        }
        let d: any = null;
        try {
            date = date.split(".")[0];
            d = new Date(date.replace(/-/g, '/'));
            //if date time
            //if (date.length > 8) {
            //    d.setHours(d.getHours() - (d.getTimezoneOffset() / 60));
            //}
        } catch (e) {
            try {
                d = new Date(date);
            } catch (e) {

            }
        }
        return d;
    }

    getStartOfMonth() {
        let d = new Date();
        d = this.newDate(new Date(d.getFullYear(), d.getMonth(), 1));
        return d;
    }

    newDateWithoutTime() {
        let d = new Date();
        d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        return d;
    }


    getUTCDate(date: any) {
        date = this.newDate(date);
        date.setHours(date.getHours() + (date.getTimezoneOffset() / 60));
        return date;
    }

    getAGNumberFormat(params: any) {
        try {
            if (params.value == null) {
                return "0.00";
            }
            let tmpNumber = Number(params.value).toFixed(2);
            let stmpNumber = tmpNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return stmpNumber.replace(".00", "");
        } catch (e) {
            return "";
        }
    }


    getAGDateFormat(params: any) {

        let get2DigitZeroPad = function (data: any): string {
            let str = "" + data;
            let pad = "00";
            let ans = pad.substring(0, pad.length - str.length) + str;
            return ans;
        };

        try {
            let d = this.newDate(params.value);
            let sReturn = d.getFullYear() + "-" + get2DigitZeroPad((d.getMonth() + 1)) + "-" + get2DigitZeroPad(d.getDate());
            return sReturn;
        } catch (e) {
            return "";
        }
    }

    getAGRelatedFormat(params: any) {
        try {
            return params.value[1];
        } catch (e) {
            return "";
        }
    }


    getOptionDataString(data: any[], valueField: string, nameField: string) {
        let options: SelectItem[] = [];
        for (let d of data) {
            options.push({ label: d[nameField], value: d[valueField] });
        }
        return options;
    }


    getOptionData(data: any[], valueField: string, nameField: string) {
        let options: SelectItem[] = [];
        for (let d of data) {
            options.push({ label: d[nameField], value: Number(d[valueField]) });
        }
        return options;
    }


    setOneToManyField(data: any) {
        if (!data) {
            return [];
        }
        let r =
            [
                [
                    6,
                    false,
                    data
                ]
            ];
        return r;
    }


    addLineManyToOneField(parent: any[], o: any) {
        if (parent == null) {
            parent = [];
        }
        parent.push([0, "", o]);
    }

    getAGPaymenTermLineOptionFormat(params: any) {
        return this.getPaymenTermLineOptionFormat(params.value);
    }


    getPaymenTermLineOptionFormat(value: string) {
        if (value == "day_after_invoice_date") {
            return "Day(s) after the invoice date";
        }
        if (value == "fix_day_following_month") {
            return "Day(s) after the end of the invoice month (Net EOM)";
        }
        if (value == "last_day_following_month") {
            return "Last day of following month";
        }
        if (value == "last_day_current_month") {
            return "Last day of current month";
        }
        return "";
    }

    getAGPaymenTermLineDueTypeFormat(params: any) {
        return this.getPaymenTermLineDueTypeFormat(params.value);
    }

    getPaymenTermLineDueTypeFormat(value: any) {
        if (value == "balance") {
            return "Balance";
        }
        if (value == "percent") {
            return "Percent";
        }
        if (value == "fixed") {
            return "Fixed Amount";
        }

        return "";
    }


    getAGStatusFormat(params: any) {
        try {
            return this.getStatusFormat(params);
        } catch (e) {
            console.log(params.value);
            return "";
        }
    }

    getStatusFormat(value: any) {
        try {
            if (value == null) {
                return "ไม่กำหนด";
            }

            switch (value) {
                case "sale":
                    return "Sale";
                case "done":
                    return "Done";
                case "draft":
                    return "Draft";
                case "confirm":
                    return "Confirm";
                case "invoice":
                    return "Invoice";
                case "cancel":
                    return "Cancel";
                default:
                    console.log(value);
                    return "";
            }
        } catch (e) {
            console.log(value);
            return "";
        }
    }

    getAGBooleanFormat(params: any) {
        try {
            if (params.value == null || !params.value) {
                return "No";
            } else {
                return "Yes";
            }
        } catch (e) {
            return "";
        }
    }

    getCostMethodFormat(value: any) {
        try {
            if (value == null) {
                return "ไม่กำหนด";
            }

            switch (value) {
                case "standard":
                    return "Standard Price";
                case "fifo":
                    return "First In First Out (FIFO)";
                case "average":
                    return "Average Cost (AVCO)";
                default:
                    console.log(value);
                    return "";
            }
        } catch (e) {
            console.log(value);
            return "";
        }
    }

    getPropertyValuationFormat(value: any) {
        try {
            if (value == null) {
                return "ไม่กำหนด";
            }

            switch (value) {
                case "manual_periodic":
                    return "Manual Price";
                case "real_time":
                    return "Automated";
                default:
                    console.log(value);
                    return "";
            }
        } catch (e) {
            console.log(value);
            return "";
        }
    }

    getBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*;base64,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });
    }


    getDisplayDateFormat(d: Date): string {

        if (!(d instanceof Date)) {
            try {
                d = this.newDate(d);
            } catch (e) {
                return "";
            }

        }
        let sReturn = "";


        sReturn = this.pad(d.getDate()) + "/" + this.pad((d.getMonth() + 1)) + "/" + d.getFullYear();

        return sReturn;

    }

    getDisplayThaiDateFormat(d: Date): string {

        if (!(d instanceof Date)) {
            try {
                d = this.newDate(d);
            } catch (e) {
                return "";
            }

        }
        let sReturn = "";


        sReturn = this.pad(d.getDate()) + " " + this.pad((d.getMonth() + 1)) + " " + (d.getFullYear() + 543);

        return sReturn;

    }

    pad(n: any) {
        let size = 2;
        let s = n + "";
        while (s.length < (size || 2)) { s = "0" + s; }
        return s;
    }

    b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    downloadURI(uri, name) {
        var link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        link.href = uri;
        link.click();
    }

    previewPrintingFile(data: any) {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    }

    downloadFile(data: any) {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    }


    getSortOrder(order: number) {
        if (order == 1) {
            return " asc";
        }
        return " desc";
    }


    getNumeric(value: any) {
        let d = Number(value);
        d = Math.round(d * 100.0) / 100.0;
        return d;
    }

    getBase64FromFile(f: File) {
        return new Promise(resolve => {
            var reader = new FileReader();
            // Read file content on file loaded event
            reader.onloadend = function (event: any) {
                let value: string = event.target.result;
                resolve(value.split(",")[1]);
            };
            // Convert data to base64 
            reader.readAsDataURL(f);
        });
    }



    getUploadFullPath(file_name: string) {
        return this.bgd.file_source + file_name + "?" + this.getRandomData().toString();
    }


    getRandomData() {
        return Math.random();
    }



    getString(value: any) {
        if (value) {
            return value + "";
        }
        return "";
    }


    loadPDFJSScript() {
        if (typeof jsPDF !== 'undefined') {
            return;
        }

        const dynamicScripts = [
            'assets/js/html2canvas.js',
            'assets/js/jspdf.min.js',
            'assets/js/THSarabunNew-normal.js',
            'assets/js/THSarabunNew-bold.js',
            'assets/js/jspdf.plugin.autotable.min.js'
        ];
        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }
}
import { Injectable } from '@angular/core';
import { GlobalDataService } from '../globaldata.service';
import { PostService } from './PostService';
@Injectable()
export class OfflineService {

    baseUrl: string;
    offlineUrl: string;
    POSOfflineUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.baseUrl = this.gd.shareObj['BaseApi'];
        this.offlineUrl = this.gd.shareObj['OfflineApi'];
        this.POSOfflineUrl = this.gd.shareObj['POSOfflineApi'];


    }

    saveOfflineObject(req: any) {
        return this.ps.post(this.offlineUrl + "saveOfflineObject", req);
    }

    loginOffilne(req: any) {
        return this.ps.post(this.POSOfflineUrl + "getofflineLoginData", req);
    }

    savePermissionOffilne(req: any) {
        return this.ps.post(this.offlineUrl + "savePermissionOffline", req);
    }

    deletePermissionOffilne(req: any) {
        return this.ps.post(this.offlineUrl + "deletePermissionOffline", req);
    }

    getOfflineUser() {
        return this.ps.post(this.POSOfflineUrl + "getOfflineUser", {});
    }


    updateOffineUser(req: any) {
        return this.ps.post(this.offlineUrl + "updateOffineUser", req);
    }

    getOfflineItem(req: any) {
        return this.ps.post(this.POSOfflineUrl + "getofflineItemData", req);
    }
    
    getOfflineAllLocation() {
      return this.ps.post(this.offlineUrl + "getOfflineAllLocation", {});
    }

    rundocNumber(req: any) {
      return this.ps.post(this.offlineUrl + "rundocNumber", req);
    }

    getPQA(req: any) {
      return this.ps.post(this.offlineUrl + "getPQA", req)
    }
    getDataOffline(req: any) {
      return this.ps.post(this.offlineUrl + "getDataOffline", req)
    }
    sent_to_nf(req: any) {
      return this.ps.post(this.offlineUrl + "sent_to_nf", req)
    }
    getOfflineLocationAllCompany() {
      return this.ps.post(this.POSOfflineUrl + "getOfflineLocationAllCompany", {});
    }
}

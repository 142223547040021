import {Injectable} from '@angular/core';
import {GlobalDataService} from '../globaldata.service';
import {PostService} from './PostService';

@Injectable()
export class BaseService {

    baseUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.baseUrl = this.gd.shareObj['BaseApi'];
    }
    getProvinces() {
        return this.ps.post(this.baseUrl + "getProvinces", {});
    }
    getDistricts() {
        return this.ps.post(this.baseUrl + "getDistricts", {});
    }
    getAmphures() {
        return this.ps.post(this.baseUrl + "getAmphures", {});
    }
    getLicense() {
        return this.ps.post(this.baseUrl + "getLicense", {});
    }
}

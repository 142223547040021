import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalDataService } from './globaldata.service';
import * as _ from 'underscore';
declare let x_tion: any;
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { BaseService } from './services/Baseservice';
import { OfflineService } from './services/OfflineService';
declare var PullToRefresh: any;
declare var $: any;
declare var cordova: any;
declare var device: any;
declare var window: any;
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<p-toast position="top-right" appendTo="body" baseZIndex="9999"></p-toast><router-outlet></router-outlet>',
  providers: [MessageService, OfflineService]
})
export class AppComponent implements OnInit {

  msgs: Message[] = [];

  pStart = { x: 0, y: 0 };
  pStop = { x: 0, y: 0 };
  permission: boolean = true;
  reports:any = new Object();
  url: string;

  constructor(private router: Router, private gd: GlobalDataService, private messageService: MessageService, private baseService: BaseService, private offlineService: OfflineService) {
    if (this.gd.getIsPlatformBrowser()) {
      x_tion._ = _;
      x_tion.gd = this.gd;
      this.gd.shareObj['main'] = this;


      if (!this.gd.checkIsLogin()) {
        this.gd.redirect("Probiz/login");
      } else {
        //this.gd.loadLogin();
      }
      this.router.events.subscribe(event => {
            //console.log(event);
            let e: any = event;
            if (e.url != null && e.url !== '/Probiz/login') {
                this.url = e.url;
                this.checkPermission(this.url);
                if(this.permission == false){
                    alert('You have no permission to access this page.');
                    if(this.gd.getCookie("user_license_name") == 'Admin'){
                        this.gd.redirect("Probiz/user");
                    }else if(this.gd.getCookie("user_license_name") == 'User'){
                        this.gd.redirect("Probiz/contract");
                    }else{
                        this.gd.redirect("Probiz/login");
                    }
                }
            }

        });
    }

  }
  checkPermission(url: string) {
      let getLicensePage  = this.gd.getLicensePage();
      this.reports = [];
      this.reports = getLicensePage;
        if (this.reports == null) {
            this.permission = false;
            return;
        }

        if (url == "/") {
            this.permission = true;
            return;
        }
        this.permission = (_.filter(this.reports, function (r) {
            return url.indexOf(r) >= 0;
        }).length > 0);
    }
    

  addSingle(severity: string, summary: string, msg: string) {
    this.messageService.add({ severity: severity, summary: summary, detail: msg });
  }


  ngOnInit() {
    if (x_tion.loadType == "android") {
      this.loadScripts();
    }
    if (x_tion.loadType == "ios") {
      this.loadScriptsiOS();
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    
    this.gd.license = [];
//    this.gd.provinces = [];
//    this.gd.districts = [];
//    this.gd.amphures = [];
    this.baseService.getLicense().then(resData => this.gd.license = resData);
    this.baseService.getProvinces().then(resData => this.gd.provinces = resData);
    this.baseService.getDistricts().then(resData => this.gd.districts = resData);
    this.baseService.getAmphures().then(resData => this.gd.amphures = resData);
//    this.baseService.getOfflineTbluser().then(resData => this.gd.users = resData);
        
//    this.getOfflineItem();
//    this.getPermisions();
    //this.gd.deleteStoreItem("listProduct");
    //let that = this;
    //document.addEventListener('touchstart', function (e) { that.swipeStart(e); }, false);
    //document.addEventListener('touchend', function (e) { that.swipeEnd(e); }, false);


    const ptr = PullToRefresh.init({
      mainElement: 'body',
      onRefresh() {
        window.location.reload();
      },
      shouldPullToRefresh: function () {
        return !window.scrollY && !$(".modal-backdrop").is(":visible") && !$(".ui-sidebar-left.ui-sidebar-active").is(":visible");
      }
    });

  }
  getPermisions() {
//    try {
//      if (!this.gd.getStoreItem("tbluser_id")) {
//        return
//      }
//      let req: any = new Object();
//      req.id = this.gd.getStoreItem("tbluser_id");
//      this.baseService.getPermision(req).then(resData => {
//        this.gd.userPermission = resData;
//        localStorage.removeItem('userPermision');
//        localStorage.setItem('userPermision', JSON.stringify(resData));
//      });
//    } catch (e) {
//      console.log(e);
//    }
//    try {
//      if (!this.gd.getStoreItem("tbluser_id")) {
//        return
//      }
//      let req: any = new Object();
//      req.id = this.gd.getStoreItem("tbluser_id");
//      this.baseService.getPermisionMain(req).then(resData => {
//        this.gd.userPermissionMain = resData;
//        localStorage.removeItem('userPermissionMain');
//        localStorage.setItem('userPermissionMain', JSON.stringify(resData));
//      });
//    } catch (e) {
//      console.log(e);
//    }
  }


  loadScripts() {

    if (typeof device !== 'undefined') {
      return;
    }

    const dynamicScripts = [
      'assets/js/cordova-android/cordova.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }


    document.addEventListener("deviceready", this.onDeviceReady, false);

  }



  loadScriptsiOS() {

    if (typeof device !== 'undefined') {
      return;
    }

    const dynamicScripts = [
      'assets/js/cordova-ios/cordova.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }


    document.addEventListener("deviceready", this.onDeviceReady, false);
  }


  onDeviceReady() {
    x_tion.cordova = true;
    window.open = cordova.InAppBrowser.open;
    //function onResume() {
    //x_tion.main_app.gd.appResume.next();
    //};

    //document.addEventListener("resume", onResume, false);
    //x_tion.main_app.initFirebaseNative();
    //x_tion.main_app.setupOpenwith();
    if (x_tion.loadType == "android" && window.MobileAccessibility) {
      window.MobileAccessibility.usePreferredTextZoom(false);
    }
    //x_tion.main_app.loadProduct();
  }
  swipeStart(e: any) {
    if (typeof e['targetTouches'] !== "undefined") {
      var touch = e.targetTouches[0];
      this.pStart.x = touch.screenX;
      this.pStart.y = touch.screenY;
    } else {
      this.pStart.x = e.screenX;
      this.pStart.y = e.screenY;
    }
  }

  swipeEnd(e: any) {
    if (typeof e['changedTouches'] !== "undefined") {
      var touch = e.changedTouches[0];
      this.pStop.x = touch.screenX;
      this.pStop.y = touch.screenY;
    } else {
      this.pStop.x = e.screenX;
      this.pStop.y = e.screenY;
    }

    this.swipeCheck();
  }

  swipeCheck() {
    var changeY = this.pStart.y - this.pStop.y;
    var changeX = this.pStart.x - this.pStop.x;
    if (this.isPullDown(changeY, changeX)) {
      alert('Swipe Down!');
    }
  }

  isPullDown(dY: any, dX: any) {
    // methods of checking slope, length, direction of line created by swipe action 
    return dY < 0 && (
      (Math.abs(dX) <= 100 && Math.abs(dY) >= 300)
      || (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60)
    );
  }
}

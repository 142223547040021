import { Injectable, Inject } from "@angular/core"
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalDataService } from '../globaldata.service'


@Injectable()
export class OdooRPCService {
    private odoo_server: string;
    private http_auth: string;
 
    private uniq_id_counter: number = 0;
    private shouldManageSessionId: boolean = false; // try without first
    private context: Object = JSON.parse(localStorage.getItem("user_context")) || { "lang": "en_US" };
    private headers: HttpHeaders;

    constructor(
        @Inject(HttpClient) private http: HttpClient, private gd: GlobalDataService) {
    
    }

    private buildRequest(url: string, params: any) {
        this.uniq_id_counter += 1;

        this.headers = new HttpHeaders({
            "Content-Type": "application/json"
        });
        return JSON.stringify({
            id: Math.round(Math.random() * 100000),
            method: "execute",
            params: params, // payload
        });
    }

    private handleOdooErrors(response: any) {
        try {
            this.gd.loading = false;
        } catch (e) { }

        if (!response.error) {
            return response.result;
        }

        let error = response.error;
        let errorObj = {
            title: "    ",
            message: "",
            fullTrace: error
        };

        return Promise.resolve(errorObj);
    }

    private handleHttpErrors(error: any) {
        return Promise.reject(error.message || error);
    }

    public init(configs: any) {
        this.odoo_server = configs.odoo_server;
        this.http_auth = configs.http_auth || null;
        this.gd.setCookie("odoo_server", this.odoo_server);
        this.gd.setCookie("http_auth", this.http_auth);
    }

    public setOdooServer(odoo_server: string) {
        this.odoo_server = odoo_server;

        this.gd.setCookie("odoo_server", this.odoo_server);
        this.gd.setCookie("http_auth", this.http_auth);
    }

    public sendRequest(url: string, params: Object): Promise<any> {
        let body = this.buildRequest(url, params);

        if (this.odoo_server == null) {
            //this.odoo_server = this.gd.getCookie("odoo_server");
            this.odoo_server = this.gd.probizURL;
            this.http_auth = this.gd.getCookie("http_auth");
        }

        return this.http.post(this.odoo_server + url, body, { headers: this.headers })
            .toPromise()
            .then(this.handleOdooErrors)
            .catch(this.handleHttpErrors);
    }

    public getContext() {
        return this.context;
    }

    public getServer() {
        return this.odoo_server;
    }

    public call(model: string, method: string, args: any, kwargs: any) {

        kwargs = kwargs || [];
        kwargs.context = kwargs.context || {};
        (<any>Object).assign(kwargs.context, this.gd.getContext());

        let params: any[] = [];
        params.push(model);
        params.push(method);
        params.push(args);
        params.push(kwargs);
        return this.sendRequest("/json_rpc_pwc", params);
    }


}